import React, { Component } from 'react';

import './ContactPage.css';

import SocialMediaBanner from "../SocialMediaBanner/SocialMediaBanner";

class ContactPage extends Component {
  render(){
    return (
        <div className="page" style={{'text-align': 'center'}}>
            <h1>Let's get in touch!</h1>

            {/*<a href="mailto:alberto@zurini.dev">alberto@zurini.dev</a>*/}
            <SocialMediaBanner contactPage={true}/>
        </div>
    );
  }
}

export default ContactPage;