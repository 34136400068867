import React, { Component } from 'react';

import './BGCanvas.css';

class BGCanvas extends Component {
  textStyle = {fontSize: this.props.size};
  
  componentDidMount(){
    setTimeout(() => {
      window.dispatchEvent(new Event('bg_ready'));
    }, 100);
  }
  
  render() {
    return (
      <div>
        <canvas></canvas>

        <span className="title glow" style={this.textStyle}>{this.props.title}</span>
      </div>
    );
  }
}

export default BGCanvas;