import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import './App.css';

import BodyTransition from '../BodyTransition/BodyTransition';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-133481341-1');
ReactGA.pageview(window.location.pathname + window.location.search);
let pathName = window.location.pathname + window.location.search;
setInterval(() => {
  if(window.location.pathname + window.location.search !== pathName){
    ReactGA.pageview(window.location.pathname + window.location.search);
    pathName = window.location.pathname + window.location.search;
  }
}, 100);

class App extends Component {
  constructor(){
    super();
    this.state = {};
  }

  render() {
    return (
      <Router>
       <BodyTransition></BodyTransition>
      </Router>
        
    );
  }
}

export default App;
