import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import './BodyTransition.css';

import AppDrawer from '../AppDrawer/AppDrawer';

import ContactPage from '../ContactPage/ContactPage';

function handleRouting(location){
  //console.log('handleRouting ', location);
  //window.articlesAlreadyRequested = false;
  //if(location.pathname.indexOf('/blog/read') !== -1){
    //window.dispatchEvent(new CustomEvent('pause_animation', {detail: {pause_animation: true}}));
  //} else {
    //window.dispatchEvent(new CustomEvent('pause_animation', {detail: {pause_animation: false}}));
  //}
}

function BodyTransition({ location }) {
  //const notInHomeBlog = location.pathname !== "/"+this.props.rootName && location.pathname !== "/"+this.props.rootName+"/";
  //console.log(notInHomeBlog);
  //var notInHomeBlog = true;
  //console.log(location);

  return (
      <div className={{flexGrow: 1}}>
        <AppDrawer></AppDrawer>

        <TransitionGroup>
          <CSSTransition
            key={location.pathname.split('/')[1]}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'fade'}
          >
            <Switch location={location} onChange={handleRouting(location)}>
                {/*
              <Route path="/projects" component={() =>
                <BlogPage pageName="Projects" rootName="projects"/>
              }/>
              <Route path="/notes" component={() =>
                <BlogPage pageName="Notes" rootName="notes"/>
              }/>*/}
              <Route path="/contact" component={ContactPage}/>
            </Switch>
          </CSSTransition>
        </TransitionGroup>


          {/*<Article></Article>*/}
      </div>        
  );
}

export default withRouter(BodyTransition);
