import React, { Component } from 'react';
import {Link} from 'react-router-dom';
/*import { BrowserRouter as Router, Route, Switch } from "react-router-dom";*/

import Typography from '@material-ui/core/Typography';
//import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuIcon from '@material-ui/icons/Menu';

import BGCanvas from '../BGCanvas/BGCanvas';
import SocialMediaBanner from '../SocialMediaBanner/SocialMediaBanner';

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    '&:hover': {
      color: red[800],
    },
  },
});

class AppDrawer extends Component {
  constructor(){
    super();
    this.state = {
      menu: {
        open: false,
        onPage: true,
      }
    }

    this.AppBarStyle = { 
      background: 'rgba(255, 255, 255, .75)', 
      boxShadow: 'none',
      color: '#000',
      backdropFilter: 'blur(10px)'
    };
  }

  openMenu = () => {
    this.setState({menu: {open: true}});
  };

  closeMenu = () => {
    this.setState({menu: {open: false}});
  };

  render(){
    const menuItems = (
      <div>
        <List>

          <ListItem button key="Home" component={Link} to="/" onClick={this.closeMenu}>
            <ListItemIcon>
              <HomeIcon
                className={styles.icon}
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    <defs>
                      <linearGradient id="gradient1">
                        <stop offset="30%" stopColor={blue[400]} />
                        <stop offset="70%" stopColor={red[400]} />
                      </linearGradient>
                    </defs>
                    {React.cloneElement(svgProps.children[0], { fill: 'url(#gradient1)' })}
                  </svg>
                )}
              />
              </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {/*}
          <a href={"https://blog.zurini.tk"}>
            <ListItem button key="Projects" onClick={this.closeMenu}>
              <ListItemIcon>
                <SvgIcon
                  className={styles.icon}
                  fontSize="large"
                >
                  <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 11h-4v4h-2v-4H7V9h4V5h2v4h4v2z"/>
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </a>
          */}

        </List>
        
        <Divider />
        <List>

          <ListItem button key="Contact me" component={Link} to="/contact" onClick={this.closeMenu}>
            <ListItemIcon>
              <SvgIcon
                className={styles.icon}
                fontSize="large"
              >
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Contact me" />
          </ListItem>

        </List>

        <SocialMediaBanner/>
      </div>
    );

    return (
    <div>
      {
      }
      <BGCanvas title="Alberto Zurini" size="12vw" className="Vid"/>
      
      <AppBar position="fixed" style={this.AppBarStyle}>
        <Toolbar>

            
             <Typography 
             variant="h6" 
             color="inherit" 
             style={{flex: 1, textDecoration: 'none'}} 
             component={Link} 
             to="/">Alberto Zurini</Typography>
            <div>

              {/*this.state.menu.onPage ?
                (
                <IconButton color="inherit" aria-label="Menu" onClick={this.openMenu}>
                  <BackIcon />
                </IconButton>
                ) : (
                  <IconButton color="inherit" aria-label="Menu" onClick={this.openMenu}>
                    <MenuIcon />
                  </IconButton>
                )*/}
                <IconButton color="inherit" aria-label="Menu" onClick={this.openMenu}>
                  <MenuIcon />
                </IconButton>
              

            </div>

        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={this.state.menu.open} 
        onClose={this.closeMenu}
      >
        {menuItems}
      </Drawer>
    </div>
    );
  }
}

export default AppDrawer;