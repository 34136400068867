import React, { Component } from 'react';

import LinkedIn from '../Assets/img/linkedin.svg';
import Instagram from '../Assets/img/instagram.svg';
import GitHub from '../Assets/img/github.svg';
import Mail from '../Assets/img/mail.svg';
import Facebook from '../Assets/img/facebook.svg';

import './SocialMediaBanner.css';

class SocialMediaBanner extends Component {
  render(){
    return (
      <div className={!this.props.contactPage ? "SocialMediaContainer" : "contactPage"}>

          <span>
              {!this.props.contactPage ?
                  <span/>
              :
              <span>
                  <a href="mailto:alberto@zurini.dev" target="_blank"
                     rel="noopener noreferrer"><img
                          src={Mail}
                          title={"Icon by https://www.flaticon.com/authors/freepik"}
                          alt="Email"
                      /></a>
                  <a href="https://www.facebook.com/alberto.zurini/"
                     target="_blank" rel="noopener noreferrer"><img
                          src={Facebook}
                          title={"Icon by https://www.flaticon.com/authors/freepik"}
                          alt="Facebook"
                      /></a>
              </span>
              }
          </span>

          <a href="https://www.linkedin.com/in/alberto-zurini/" target="_blank"
             rel="noopener noreferrer"><img
                  src={LinkedIn}
                  alt="LinkedIn"
                  title={"Icon by https://www.flaticon.com/authors/freepik"}
              /></a>
        <a href="https://github.com/albertoZurini/" target="_blank"
           rel="noopener noreferrer"><img
              src={GitHub}
              alt="GitHub"
              title={"Icon by https://www.flaticon.com/authors/pixel-perfect"}
          /></a>
        <a href="https://www.instagram.com/alberto_zurini/" target="_blank"
           rel="noopener noreferrer"><img
              src={Instagram}
              alt="Instagram"
              title={"Icon by https://www.flaticon.com/authors/freepik"}
          /></a>

          <div className={"spacer-mobile"}/>
      </div>
    )
  }
}

export default SocialMediaBanner;